import { String } from "aws-sdk/clients/appflow";
import { BillDetials, CouponDetails } from "./Company";

export class Branch
{

  
     id:string;
     code:string;
name:String;
locationDetails:string;
phoneNumber:string;
	email:string;
	active:boolean;
	couponPaymentEnabled: boolean;
deleted:boolean;
	createdDate:string;
	updatedDate:string;
createdUsername:string;
createdByName:string;
updatedByUsername:string;
updatedByName:string;

}

export class BranchesResponseData {
    responseCode: boolean;
    message: string;
    responseObject: any;
}

export class BranchOrderSummary
{
    totalOrderCount:number;
	totalAmount:number;
	totalAmountPaid:number;
	
	billitems:BillDetials;
}

export class BranchAccountOrder
{
   
    id:string;	
	
	branchId:string;
    branchCode:string;
	branchName:string;
	couponPaymentEnabled:boolean;
	
	phoneNumber:string;
	totalAmount:string;
	cashAmount:string;
	paymentAmount:string;
	discountAmount:string;
	couponAmount:string;
	transactionNo:string;
	active:boolean;
	deleted:boolean;
	createdDate:string;
	updatedDate:string;
	createdUsername:string;
	createdByName:string;
	billdetails:BillDetials[];
    coupondetails:CouponDetails[]
	
	
	
}

export class BranchSummaryAlertsConfig
{
    id:string;
	branchCode:string;
	defaultInventoryCode:string;
	defaultInventoryName:string;
	comissionAlertsEmail:string;
	comissionAlerts:boolean;
	comissionPercantage:number;
	createdUsername:string;
	createdByName:string;
	updatedByUsername:string;
	updatedByName:string;

}