import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FoodWastage, FoodWastageData, FoodWastageJsonBean } from '../../views/bean/FoodWastage';
import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { LoggedInUserAccountDetails } from '../../views/bean/UserAccount';

@Injectable({
  providedIn: 'root'
})
export class FoodwastageService {

  currentUser:LoggedInUserAccountDetails=null; 
  
  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  saveFoodWastageData(data: FoodWastageJsonBean): Observable<FoodWastageData> {
    return this.http.post<FoodWastageData>(api.addFoodWastage, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  
 

  fetchFoodWastageDataByAnyDate(data: any): Observable<FoodWastageData> {
    return this.http.post<FoodWastageData>(api.fetchFoodWastageByAnyRange, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchFoodWastageDataByAnyDateBranchCode(data: any): Observable<FoodWastageData> {
    return this.http.post<FoodWastageData>(api.fetchFoodWastageByAnyRangeBranchCode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{
    
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }

}
