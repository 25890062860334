
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Doctor,DoctorData, DoctorOrder, DoctorPaymentDetails, DoctorPayment,ResponseDataArray } from '../../views/bean/Doctor';
import { api } from '../../../environments/api';
import { Employee } from '../../views/bean/Employee';
import { catchError } from 'rxjs/operators';
import { LoggedInUserAccountDetails } from '../../views/bean/UserAccount';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})


export class EmployeeManagementService {


  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  saveData(data: Employee): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.saveEmployee, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getByEmployeeId(data: string): Observable<Employee> {
    return this.http.post<Employee>(api.fetchEmployeeById, data, this.httpOptions).pipe(catchError(this.handleError));
  }

 

  getAllData(): Observable<ResponseDataArray> {
    return this.http.get<ResponseDataArray>(api.getAllEmployees).pipe(catchError(this.handleError));
  }


  getAllActiveData(): Observable<ResponseDataArray> {
    return this.http.get<ResponseDataArray>(api.getAllActiveEmployees).pipe(catchError(this.handleError));
  }

  activate(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.activateEmployee, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivate(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.deactivateEmployee, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  delete(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.deleteEmployee, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{
    
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }

}